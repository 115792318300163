import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { flatMap, map, publishReplay, refCount } from 'rxjs/operators';

import { environment } from '../../../../../environments/environment';
import { APIRoutes } from '../../../../shared/constants/api-routes.constant';
import { HttpInterceptor } from '../../../../shared/interceptors/http-interceptor.service';
import { OAuthService } from '../../../user/services/oauth.service';
import { SearchResult } from '../../../../shared/models/search-result.model';
import { AuthenticatedWebService } from '../../../../shared/services/authenticated.web.service';
import { LocalStorageService } from '../../../user/services/local-storage.service';
import { Vendedor } from '../../particular/models/vendedor.model';
import { stringFormat } from '../../../../shared/utils/formatter';

@Injectable()
export class VendedorService extends AuthenticatedWebService {
  assinaturaPlanoCache = new Map();

  constructor(
    private http: HttpInterceptor,
    oauthService: OAuthService,
    private localStorage: LocalStorageService
  ) {
    super(oauthService);
  }

  getDados(params): Observable<SearchResult> {
    let url = environment.api.base_api + APIRoutes.VENDEDOR_PARTICULAR;

    return this.http.get(url, { headers: this.httpHeaders, params: params }).pipe(
      map((res: any) => res),
      map((res) => {
        let result = Array<Vendedor>();
        res.data.forEach((e) => {
          result.push(
            new Vendedor(
              e.id,
              e.colaborador,
              e.created_at,
              e.updated_at,
              e.email,
              e.externo_detalhes,
              e.externo_id,
              e.organizacao_id,
              e.plano_id,
              e.telefone,
              e.tipo,
              e.vendedor_status_id,
              e.detalhe,
              e.plano ? e.plano : undefined,
              e.vendedor_status,
              e.acordo_operacional,
              undefined,
              e.conta_id,
              e.conta ? e.conta : undefined,
              undefined,
              e.default_gateway_id,
              e.pode_alterar_plano,
              e.vendedor_gateways
            )
          );
        });
        let searchResult = new SearchResult(result, res.meta.pagination);

        return searchResult;
      })
    );
  }

  create(vendedor: Vendedor) {
    let url = environment.api.base_api + APIRoutes.VENDEDOR_PARTICULAR;

    return this.http
      .post(url, vendedor, { headers: this.httpHeaders })
      .pipe(map((res: any) => res));
  }

  updateFirstStep(vendedor: Vendedor) {
    let url =
      environment.api.base_api +
      APIRoutes.VENDEDOR_PARTICULAR +
      '/' +
      vendedor.id +
      '?include=detalhe,detalhe.endereco,detalhe.dono_endereco';

    return this.http
      .put(url, vendedor, { headers: this.httpHeaders })
      .pipe(map((res: any) => res));
  }

  update(vendedorUpdate: any) {
    let url = environment.api.base_api + APIRoutes.VENDEDOR_PARTICULAR_UPDATE;

    return this.http
      .post(url, vendedorUpdate, { headers: this.httpHeadersFormData })
      .pipe(map((res: any) => res));
  }

  /**
   * @payload: {
   *  seller_id: string
   *  type: string,
   *  file: File
   * }
   */
  updateV2(vendedorUpdate: any, organizacaoId: string, vendedorId: string) {
    const apiRoute = stringFormat(APIRoutes.DOCUMENT_UPLOAD, organizacaoId, vendedorId);
    let url = `${environment.api.base_url}api/v2/${apiRoute}`;

    return this.http
      .post(url, vendedorUpdate, { headers: this.httpHeadersFormData })
      .pipe(map((res: any) => res));
  }

  delete(id: any) {
    let url = environment.api.base_api + APIRoutes.VENDEDOR_PARTICULAR + '/' + id;

    return this.http
      .delete(url, { headers: this.httpHeaders })
      .pipe(map((res: any) => res));
  }

  get(id: string) {
    let url = environment.api.base_api + APIRoutes.VENDEDOR_PARTICULAR + '/' + id;
    let params = new HttpParams()
      .set(
        'include',
        'detalhe,detalhe.endereco,detalhe.dono_endereco,vendedor_status,plano,especialidades_medicas,pode_alterar_plano'
      )
      .set('organizacao_id', this.localStorage.getOrganizacaoId());

    return this.http.get(url, { headers: this.httpHeaders, params: params }).pipe(
      map((res: any) => res),
      map((res) => {
        let result = Array<Vendedor>();
        res.data.forEach((vendedor) => {
          result.push(
            new Vendedor(
              vendedor.id,
              vendedor.colaborador,
              vendedor.created_at,
              vendedor.updated_at,
              vendedor.email,
              vendedor.externo_detalhes,
              vendedor.externo_id,
              vendedor.organizacao_id,
              vendedor.plano_id,
              vendedor.telefone,
              vendedor.tipo,
              vendedor.vendedor_status_id,
              vendedor.detalhe.data,
              vendedor.plano ? vendedor.plano.data : undefined,
              vendedor.vendedor_status.data,
              vendedor.acordo_operacional,
              undefined,
              vendedor.conta_id,
              vendedor.conta,
              vendedor.especialidades_medicas && vendedor.especialidades_medicas.data,
              vendedor.default_gateway_id,
              vendedor.pode_alterar_plano
            )
          );
        });
        let searchResult = new SearchResult(result, null);

        return searchResult;
      })
    );
  }

  patch(data: any, id: any, organizacao_id: any) {
    let url = environment.api.base_api + APIRoutes.VENDEDOR_PARTICULAR + '/' + id;

    return this.http
      .patch(url, data, { headers: this.httpHeaders })
      .pipe(map((res: any) => res));
  }

  getAssinaturaPlano() {
    const organizacaoId = this.localStorage.getOrganizacaoId();
    if (this.assinaturaPlanoCache.has(organizacaoId)) {
      return this.assinaturaPlanoCache.get(organizacaoId);
    }

    const url = environment.api.base_api + APIRoutes.ORGANIZACAO_ASSINATURA_PLANO;
    const params = new HttpParams().set('organizacao_id', organizacaoId);

    const observable = this.http
      .get(url, { headers: this.httpHeaders, params: params })
      .pipe(
        map((res: any) => res),
        publishReplay(),
        refCount()
      );
    this.assinaturaPlanoCache.set(organizacaoId, observable);
    return observable;
  }

  getPlanos() {
    let planoId = 'a2699466-06d3-4e99-b5b5-547c914fece8'; // Orange em caso de erro
    return this.getAssinaturaPlano().pipe(
      flatMap((res: any): Observable<any> => {
        try {
          planoId = res.data[0].organizacao_plano_id;
        } catch (e) {
          console.error('Erro obtendo plano para taxas', e);
        }

        const params = new HttpParams()
          .set('organizacao_id', this.localStorage.getOrganizacaoId())
          .set('organizacao_plano_id', planoId);

        return this.http.get(environment.api.base_api + APIRoutes.VENDEDOR_PLANO, {
          headers: this.httpHeaders,
          params: params,
        });
      }),
      map((res: any) => {
        return res;
      }),
      map((res: any) => ({
        ...res,
        data: res.data.filter(
          (plano) =>
            plano.organizacao_plano_id === planoId && plano.ativo && plano.visivel
        ),
      }))
    );
  }

  getTaxas() {
    return this.getAssinaturaPlano().pipe(
      flatMap((res: any): Observable<any> => {
        let planoId = 'a2699466-06d3-4e99-b5b5-547c914fece8'; // Orange em caso de erro
        try {
          planoId = res.data[0].organizacao_plano_id;
        } catch (e) {
          console.error('Erro obtendo plano para taxas', e);
        }
        return this.http.get(
          'https://opendata.medicinaesolutions.com/taxas_pagamento_particular/' +
            planoId +
            '/latest.json'
        );
      }),
      map((res: any) => {
        return res;
      })
    );
  }

  getTaxasPlanos() {
    const organizacaoPlanoId = this.localStorage.getOrganizacaoPlanoId();
    const url =
      environment.api.base_api_v2 +
      stringFormat(APIRoutes.ORGANIZACAO_PLANO_TAXAS, organizacaoPlanoId);

    return this.http.get(url, { headers: this.httpHeaders, params: null });
  }

  getVendedorLimt(organizacaoId) {
    let url = `${environment.api.base_api}${APIRoutes.ORGANIZACAO}/${organizacaoId}/${APIRoutes.LIMITE_VENDEDOR}`;
    return this.http
      .get(url, { params: null, headers: this.httpHeaders })
      .pipe(map((res: any) => res.data[0]));
  }

  getPlanosSimulador(organizacaoId, organizacaoPlanoId) {
    let url = `${environment.api.base_api}plano?organizacao_id=${organizacaoId}&organizacao_plano_id=${organizacaoPlanoId}&ativo=1&visivel=1`;
    return this.http
      .get(url, { params: { include: 'plano_taxas' }, headers: this.httpHeaders })
      .pipe(map((res: any) => res.data));
  }

  updatePlan(organizationId: string, sellerId: string, data: any) {
    let url = `${environment.api.base_url}api/v2/organizations/${organizationId}/sellers/${sellerId}/plans`;

    return this.http
      .patch(url, data, { headers: this.httpHeaders })
      .pipe(map((res: any) => res));
  }

  storeOrUdatePlanV2(organizationId: string, sellerId: string, data: any) {
    let url = `${environment.api.base_url}api/v2/organizations/${organizationId}/sellers/${sellerId}/plans`;

    return this.http
      .put(url, data, { headers: this.httpHeaders })
      .pipe(map((res: any) => res));
  }

  postAcceptOperationalAgreement(organizationId: string, sellerId: string) {
    let url = `${environment.api.base_url}api/v2/organizations/${organizationId}/sellers/${sellerId}/accept-operational-agreement`;

    return this.http
      .post(url, null, { headers: this.httpHeaders })
      .pipe(map((res: any) => res));
  }

  getV2(organizationId: string, sellerId: string) {
    let url = `${environment.api.base_url}api/v2/organizations/${organizationId}/sellers/${sellerId}`;

    return this.http.get(url, { headers: this.httpHeaders }).pipe(map((res: any) => res));
  }

  generateKyc(sellerId: string, organizationId: string) {
    let url = `${environment.api.base_url}api/v2/organizations/${organizationId}/sellers/${sellerId}/kyc_url`;

    return this.http.get(url, { headers: this.httpHeaders }).pipe(map((res: any) => res));
  }
}
